import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import PostList from "../components/PostList";
import Pagination from "../components/Pagination";

class BlogPage extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const { edges: posts } = data.allWordpressPost;

    return (
      <Layout isHomepage={true}>
        <PostList posts={posts} />
        <Pagination pageContext={pageContext} pathPrefix="/" />
      </Layout>
    );
  }
}

BlogPage.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
};

export default BlogPage;

export const pageQuery = graphql`
  query IndexQuery($limit: Int, $skip: Int) {
    allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
  }
`;
