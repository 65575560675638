import React from "react";
import { Link } from "gatsby";
import { URLS } from "../../config";
import "./Pagination.scss";

const Pagination = ({ pageContext }) => {
  const { currentPage, numPages, nextPagePath } = pageContext;

  const pageUrls = [];

  for (let i = 0; i < numPages; i++) {
    const path =
      i === 0 ? URLS.PAGINATION_ZERO : `/${URLS.PAGINATION}/${i + 1}`;

    pageUrls.push(path);
  }

  return (
    <nav className="pagination" role="navigation">
      <ul className="page-numbers">
        {pageUrls.map((pageUrl, index) => (
          <li key={pageUrl} className="navbar-item">
            {index + 1 === currentPage ? (
              <span aria-current="page" className="page-numbers current">
                {index + 1}
              </span>
            ) : (
              <Link className="page-numbers" to={pageUrl}>
                {index + 1}
              </Link>
            )}
          </li>
        ))}
        {nextPagePath && (
          <li className="navbar-item">
            <Link to={nextPagePath} className="next page-numbers" rel="next">
              Next
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

export { Pagination };
