import React from "react";
import { Link } from "gatsby";
import Title from "../Title";
import EntryContent from "../EntryContent";
import Byline from "../Byline";

function PostItem({ post, showReadMore = false }) {
  return (
    <article className="post hentry">
      <header className="hentry__header">
        <h2 className="h2 entry-title">
          <Link to={`/${post.slug}`}>
            <Title title={post.title} />
          </Link>
        </h2>
        <Byline dateTime={post.dateTime} dateFormatted={post.dateFormatted} />
      </header>

      <EntryContent>
        <div
          dangerouslySetInnerHTML={{
            __html: post.excerpt.rendered
          }}
        />
        {showReadMore && (
          <Link className="excerpt-read-more" to={`/${post.slug}`}>
            Read more »
          </Link>
        )}
      </EntryContent>
    </article>
  );
}

export { PostItem };
