import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import PostItem from "./PostItem";

export default class IndexPage extends React.Component {
  render() {
    const { posts } = this.props;

    return (
      <>
        {posts.map(({ node }) => (
          <PostItem key={node.id} post={node} />
        ))}
      </>
    );
  }
}

IndexPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
};

export const pageQuery = graphql`
  fragment PostListFields on WordpressPost {
    id
    title {
      rendered
    }
    excerpt {
      rendered
    }
    author
    dateTime: date
    dateFormatted: date(formatString: "Do MMMM YYYY")
    slug
  }
`;
